import React from 'react';
import {extendTheme, theme as base,} from '@chakra-ui/react';
import Input from "@/styles/theme/components/input";
import Button from "@/styles/theme/components/button";
import CloseButton from "@/styles/theme/components/closeButton";
import Textarea from "@/styles/theme/components/textarea";

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "white",
                padding: "0px",
                margin: "0px",
                boxSizing: "border-box",
                width: "100vw",
                height: "100vh",
                lineHeight: "133%",
                color: "black",
                overflowX: "visible",
                fontFamily: "Montserrat, sans-serif"
            }
        }
    },
    space: {
        4.5: "1.125rem",
        11: "11px",
        14: "14px",
        19: "19px",
        30: "30px",
        32: "32px",
        34: "34px",
    },
    colors: {
        primary: {
            50: "#ecf0fb",
            100: "#c2cff2",
            200: "#99aee8",
            300: "#6f8ddf",
            400: "#2a52be",
            500: "#214094",
            600: "#182e6a",
            700: "#0e1c41",
            800: "#050a17",
        },
        cgray: {
            100: '#F9F9F9',
            200: '#828282',
        },
        cpurple: '#2A52BE',
    },
    components: {
        Input,
        Textarea,
        Button,
        CloseButton,
    },
    fonts: {
        heading: `Montserrat, ${base.fonts?.heading}`,
        body: `Montserrat, ${base.fonts?.body}`,
        mono: `Montserrat, ${base.fonts?.mono}`,
    },
    fontWeights: {
        normal: 400,
        regular: 500,
        medium: 600,
        bold: 700
    },
});

export default theme;
