const CloseButton = {
    baseStyle: {
        width: "24px",
        height: "24px",
        fontWeight: 'semibold', // Normally, it is "semibold"
        padding: "18px"
    },
    // 2. We can add a new button size or extend existing
    variants: {
        red: {
            borderRadius: '100%',
            bg: '#E01111',
            color: "white",
            fontSize: '16px',
            _hover: {
                bg: '#bb0404',
            },
            _active: {
                bg: '#bb0404',
                outline: "none",
            },
            _focus: {
                bg: '#bb0404',
                outline: "none",
            }
        },
        red2: {
            borderRadius: '6px',
            bg: '#E01111',
            color: "white",
            fontSize: '14px',
            _hover: {
                bg: '#bb0404',
            },
            _active: {
                bg: '#bb0404',
                outline: "none",
            },
            _focus: {
                bg: '#bb0404',
                outline: "none",
            }
        }
    },
};

export default CloseButton;
