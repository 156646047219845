const Textarea = {
    baseStyle: {
        field: {
            bg: '#fff',
            color: '#000',
            fontSize: '15px',
            fontFamily: 'Montserrat, sans-serif',
            lineHeight: "133%",
            border: '1px solid',
            borderColor: "gray.500",
            paddingY: '16px',
            paddingX: '42px',
        },
    },
    variants: {
        solid: {
            field: {
                borderRadius: '10px',
                border: '1px solid',
                borderColor: 'red.500'
            },
        },

    },
    defaultProps: {
        size: null,
        variant: 'solid',
    },
};
export default Textarea;
