const API_BASE = process.env.NEXT_PUBLIC_API_HOST;

export const makeUrl = (endpoint) => {
    return API_BASE + endpoint;
};

export const authenticate = async (email, password) => {
    let url = makeUrl("user/login")
    return await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            "email": email,
            "password": password
        }),
        headers: new Headers({"Content-Type": "application/json"}),
        // credentials: "include",
    });
};

export const signup = (email, password) => {
    let url = makeUrl("user/login/")
    return fetch(url, {
        method: "POST",
        body: JSON.stringify({
            "email": email,
            "password": password
        }),
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};


export const resetPassword = (email) => {
    let url = makeUrl("user/password_reset/")
    return fetch(url, {
        method: "POST",
        body: JSON.stringify({
            "email": email,
        }),
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateUser = (id, data) => {
    let url = makeUrl(`account/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const getUser = (id, token) => {
    let url = makeUrl(`account/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};


export const deleteUser = (id) => {
    let url = makeUrl(`account/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const deleteNew = (id, token) => {
    let url = makeUrl(`news/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const fetchAllNews = () => {
    let url = makeUrl(`news/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const fetchAllUsers = () => {
    let url = makeUrl(`account/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const fetchAllStaff = () => {
    let url = makeUrl(`account/?is_staff=true`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const fetchAllClients = () => {
    let url = makeUrl(`account/?default_role=user&is_staff=false`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const createUser = (data) => {
    let url = makeUrl(`account/`)
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const createAd = (token, request, options) => {
    let error = null
    let data = {}
    let status = null
    request(options, function (error, response, body) {
        if (error) {
            error = new Error(error);
        }
        const res = response.toJSON()
        status = res.statusCode
        data = res.body
    });
    return {
        error,
        status,
        data
    }
};

export const fetchAllAds = () => {
    let url = makeUrl(`espacePublicitaire/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const getAd = (id) => {
    let url = makeUrl(`espacePublicitaire/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateAd = (id, data) => {
    let url = makeUrl(`espacePublicitaire/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const deleteAd = (id, token) => {
    let url = makeUrl(`espacePublicitaire/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const createService = (token, data) => {
    let url = makeUrl(`service/`)
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const fetchAllServices = () => {
    let url = makeUrl(`service/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json",}),
        credentials: "include",
    });
};

export const getService = (id) => {
    let url = makeUrl(`service/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateService = (id, data, token) => {
    let url = makeUrl(`service/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const deleteService = (id, token) => {
    let url = makeUrl(`service/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};


export const fetchAllMails = () => {
    let url = makeUrl(`contact/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};


export const deleteMail = (id, token) => {
    let url = makeUrl(`contact/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const createAnimator = (token, data) => {
    let url = makeUrl(`animateur/`)
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const fetchAllAnimators = () => {
    let url = makeUrl(`animateur/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const getAnimator = (id) => {
    let url = makeUrl(`animateur/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateAnimator = (id, data, token) => {
    let url = makeUrl(`animateur/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const deleteAnimator = (id, token) => {
    let url = makeUrl(`animateur/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const createCategory = (token, data) => {
    let url = makeUrl(`categorie/`)
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const fetchAllCategories = () => {
    let url = makeUrl(`categorie/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const getCategory = (id) => {
    let url = makeUrl(`categorie/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateCategory = (id, data, token) => {
    let url = makeUrl(`categorie/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const deleteCategory = (id, token) => {
    let url = makeUrl(`categorie/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};


export const fetchAllRequests = () => {
    let url = makeUrl(`demande/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const getRequest = (id) => {
    let url = makeUrl(`demande/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateRequest = (id, data, token) => {
    let url = makeUrl(`demande/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const deleteRequest = (id, token) => {
    let url = makeUrl(`demande/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const fetchAllPrograms = (type) => {
    let url = makeUrl(`programme/?type=${type}`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const fetchAllPopulars = (type) => {
    let typ = type === "popular_tv" ? type : "popular_radio"
    let url = makeUrl(`favoris/${typ}`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const getProgram = (id) => {
    let url = makeUrl(`programme/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateProgram = (id, data) => {
    let url = makeUrl(`programme/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const deleteProgram = (id, token) => {
    let url = makeUrl(`programme/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};

export const fetchAllSubPrograms = () => {
    let url = makeUrl(`subProgramme/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const getSubProgram = (id) => {
    let url = makeUrl(`subProgramme/${id}/`)
    return fetch(url, {
        method: "GET",
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const updateSubProgram = (id, data) => {
    let url = makeUrl(`subProgramme/${id}/`)
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: new Headers({"Content-Type": "application/json"}),
        credentials: "include",
    });
};

export const deleteSubProgram = (id, token) => {
    let url = makeUrl(`subProgramme/${id}/`)
    return fetch(url, {
        method: "DELETE",
        headers: new Headers({"Content-Type": "application/json", "Authorization": `Token ${token}`}),
        credentials: "include",
    });
};