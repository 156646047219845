const Input = {
    baseStyle: {
        field: {
            bg: '#fff',
            color: '#000',
            fontSize: '15px',
            fontFamily: 'Montserrat, sans-serif',
            lineHeight: "133%",
            paddingY: '16px',
            paddingX: '42px',
        },
    },
    variants: {
        solid: {
            field: {
                borderRadius: '10px',
                border: '1px solid gray',
            },
        },
        rounded: {
            field: {
                borderRadius: '9999px',
                border: '1px solid #EAEAEA',
            },
        },
        search: {
            field: {
                borderRadius: '40px',
                bg: '#F9F9F9',
                color: 'gray.700',
                pl: "44px",
                _focus: {
                    outlineColor: "primary.200",
                    border: '2px solid',
                    borderColor: 'primary.50'

                }
            },
        },
    },
    defaultProps: {
        size: null,
        variant: 'solid',
    },
};
export default Input;
