import {createContext, useContext, useEffect, useState} from "react";
import {signup} from "lib/db";
import {useRouter} from "next/router";
import {fetchToken, logout, updateUserProfile, userMe} from "@/services/users";
import cookieCutter from 'cookie-cutter'
import {useToggle} from "rooks";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const {Provider} = AuthContext;


const AuthProvider = ({children}) => {
    const auth = useApiAuth()
    return (
        <Provider value={auth}>
            {children}
        </Provider>
    )
}

export async function setUserCookie(request, response) {
    const cookie = request.cookies['token']

    if (!cookie) {
        response.redirect("/login")
    }

    return response
}

export const formatAuthUser = (data) => {
    return {
        id: data.user?.id,
        email: data.user?.email,
        fullName: data.user?.first_name + " " + data.user?.last_name,
        first_name: data.user?.first_name,
        last_name: data.user?.last_name,
        username: data.user?.username,
        role: data.user?.default_role,
        profile: data.user?.profil_image,
        emailVerified: data.user?.is_verify_email,
        token: data?.token
    };
};

const formatUser = (data) => {
    return {
        id: data?.id,
        email: data?.email,
        fullName: data?.first_name + " " + data?.last_name,
        first_name: data?.first_name,
        last_name: data?.last_name,
        username: data?.username,
        role: data?.default_role,
        profile: data?.profil_image,
        emailVerified: data?.is_verify_email,
        token: data?.token
    };
};


const customToggleFunction = (v) => {
    const x = v === "tv" ? "radio" : "tv"
    if (typeof window !== "undefined") {
        if (!localStorage.getItem("interface")) {
            localStorage.setItem("interface", x)
        } else {
            localStorage.setItem("interface", x)
        }
    }
    return x
};


export default function useApiAuth() {
    const router = useRouter();
    const [authState, setAuthState] = useState(null);
    const [authError, setAuthError] = useState(null)
    const [authSuccess, setAuthSuccess] = useState(null)
    const [x_interface, toggleXInterface] = useToggle(authState?.userInfo?.role === "userRadio" ? "radio" : "tv", customToggleFunction);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!localStorage.getItem("interface")) {
            localStorage.setItem("interface", x_interface)
        } else {
            localStorage.setItem("interface", x_interface)
        }
        checkAuth()
    }, [])

    const handleUser = (rawUser, remember_me = false) => {
        if (rawUser) {
            const user = formatAuthUser(rawUser);
            const {token, ...userWithoutToken} = user;

            setAuthState({
                userInfo: userWithoutToken
            });

            if (remember_me && token) {
                cookieCutter?.set('eden-valid', 101, {
                    expires: 7
                });
            } else {
                cookieCutter?.set('eden-valid', 101, {
                    expires: 1
                });
            }
            setLoading(false);
            return user;

        } else {
            setAuthState({
                userInfo: {}
            });
            cookieCutter?.set('eden-valid', 0, {expires: new Date(0)})
            cookieCutter?.set('token', '', {expires: new Date(0)})
            cookieCutter?.set('userInfo', '', {expires: new Date(0)})
            setLoading(false);
            return false;
        }
    };

    const isAuthenticated = () => {
        return !(authState === null);
    };

    const getInterface = () => {
        const interf = authState?.userInfo?.role === "userRadio" ? "radio" : "tv"
        if (interf === x_interface) {
            return x_interface
        } else {
            toggleXInterface()
            return interf
        }
    };

    const isAdmin = () => {
        return authState?.userInfo?.role === "admin" || authState?.userInfo?.role === "super-admin";
    };

    const checkAuthUser = () => {
        if (authState) {
            router.push("/dashboard")
        } else {
            setLoading(false)
            router.push("/login")
        }

    }

    const login = async (data) => {
        const {email, password} = data
        const resp = await fetchToken(email, password)
        if (resp.ok) {
            const resp_json = await resp?.json()
            if (typeof window !== 'undefined') {
                setAuthSuccess("Connexion réussie.")
            }
            handleUser(resp_json.data)
            setLoading(false)
            setAuthSuccess("Connexion réussie.")
            return resp_json
        } else {
            const resp_json = await resp?.json()
            setLoading(false)
            if (typeof window !== 'undefined') {
                setAuthError(resp_json.data)
            }
            setAuthError(resp_json.data)
            return resp_json
        }
    }

    const register = async (data) => {
        const {email, password} = data
        return await signup(email, password)
    }

    const logoutUser = async () => {
        const resp = await logout()
        if (resp?.user?.status === "OK") {
            cookieCutter?.set('eden-valid', 0, {expires: new Date(0)})
            setAuthState(null);
            await router.push("/login")
        }
    }

    const checkAuth = async () => {
        const resp = await userMe()
        const user = resp?.user?.me
        if (user && Object.keys(user).includes("id")) {
            setAuthState({userInfo: formatUser(user)})
            if (router.pathname === "/login") {
                await router.push("/dashboard")
            }
        } else {
            setAuthState(null)
            await router.push("/login")
        }
    }

    const updateProfile = async (id, data) => {
        setLoading(true)
        return await updateUserProfile(id, data)
    }

    const resetUserPassword = async (email) => {
        setLoading(true)
    }

    const deleteUser = async () => {
        setLoading(true)
    }

    const updateUserPassword = async (newPassword) => {
        setLoading(true)
    }


    return {
        authState,
        isAdmin: isAdmin(),
        setAuthState,
        x_interface: isAdmin ? x_interface : getInterface(),
        toggleXInterface,
        isAuthenticated: isAuthenticated(),
        loading,
        authError,
        authSuccess,
        setAuthSuccess,
        setAuthError,
        setLoading,
        register,
        login,
        logoutUser,
        checkAuthUser,
        deleteUser,
        updateProfile,
        updateUserPassword,
        resetUserPassword,
    };
}

export {AuthContext, AuthProvider}