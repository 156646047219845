import axios from "axios";

const fetchWithMethod = async (params) => {
    if (params.url) {
        // request interceptor to add token to request headers
        axios.interceptors.request.use(
            async (config) => {
                const token = params.token || null;

                if (token) {
                    config.headers = {
                        authorization: `Bearer ${token}`
                    };
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        // response interceptor intercepting 401 responses, refreshing token and retrying the request
        axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const config = error.config;

                if (error.response.status === 401 && !config._retry) {
                    config._retry = true;
                    return axios(config);
                }

                return Promise.reject(error);
            }
        );

        let data = null;
        let error = ""
        let loaded

        try {
            const result = await axios.request(params);
            data = result.data
        } catch (e) {
            error = e
        } finally {
            loaded = true
        }

        // const res = await fetch(params.url, {
        //     method: params.method,
        //     body: params.method in ['POST', 'PUT', 'PATCH'] ? JSON.stringify(params.data) : null,
        //     headers: {
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${params.token}`,
        //     },
        //     credentials: "include",
        // });
        // return res.json();

        return {data, error, loaded};

    }
};

export default fetchWithMethod;
