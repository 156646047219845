import Head from "next/head";
import {ChakraProvider} from "@chakra-ui/react";
import {AuthProvider} from "@/contexts/AuthContext";
import theme from '@/styles/theme';
import '@/styles/styles.css';
import 'video.js/dist/video-js.css';
import 'videojs-dock/dist/videojs-dock.js';
import 'videojs-dock/dist/videojs-dock.css';
import '@/styles/customTheme.scss';
import '@/styles/video.css';

import Cookies from "cookies";

export async function getServerSideProps(ctx) {
    const {req, res} = ctx
    const cookies = new Cookies(req, res)
    // Get a cookie
    const token = cookies.get('token')
    const userInfo = cookies.get('userInfo')
    return {
        props: {token, userInfo},
    };
}

function MyApp({Component, pageProps}) {
    const title = "Eden-TV Administration"
    return (
        <ChakraProvider theme={theme}>
            <Head>
                <meta charSet="utf-8"/>
                <meta content="IE=edge" httpEquiv="X-UA-Compatible"/>
                <meta content="width=device-width, initial-scale=1" name="viewport"/>
                <title>{title}</title>
            </Head>
            <AuthProvider>
                {/* <DefaultSeo {...SEO} /> */}
                <Component {...pageProps}/>
            </AuthProvider>
        </ChakraProvider>
    )
}

export default MyApp
