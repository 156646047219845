const Button = {
    baseStyle: {
        fontWeight: 'bold', // Normally, it is "semibold"
    },
    // 2. We can add a new button size or extend existing
    sizes: {
        xl: {
            h: '56px',
            fontSize: 'lg',
            px: '32px',
        },
    },
    variants: {
        cpurple: {
            bg: 'cpurple',
            color: '#fff',
        },
        cgray: {
            bg: 'cgray.100',
            color: '#000',
        },
        auth: {
            borderRadius: '40px',
            bg: '#3D5A96',
            fontSize: '24px',
            boxShadow: '0 0 2px 2px #3D5A96',
        },
    },
};

export default Button;
