import useSWR from "swr";
import fetchWithMethod from "@/utils/fetchWithMethod";
import FormData from "form-data";


export const fetchToken = async (email, password) => {
    const url = "/api/users/authenticate/"
    return fetch(url, {
        method: "POST",
        body: JSON.stringify({
            "email": email,
            "password": password
        }),
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "same-origin",
    })
}

export function useGetUser(id, token) {
    const {data, mutate, error, isValidating} = useSWR([`/api/users/${id}`, 'GET', token], fetchWithMethod);

    const loading = !data && !error;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        isValidating,
        user: data,
        mutate
    };
}

export async function updateUserProfile(id, newData) {
    const url = `/api/users/${id}`;
    let data = null;
    let error = ""
    let loaded = false

    try {
        const updateData = new FormData();
        updateData.append("first_name", newData.first_name)
        updateData.append("last_name", newData.last_name)
        updateData.append("email", newData.email)
        if (newData.profil_image?.[0]) {
            updateData.append("profil_image", newData.profil_image?.[0], newData.profil_image[0].name)
        }

        const result = await fetch(url, {
            method: 'PATCH',
            body: updateData,
        });
        data = await result.json();
    } catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        user: data,
        error
    };
}

export async function updateUser(id, newUser) {
    const url = `/api/users/${id}`;
    let data = null;
    let error = ""
    let loaded = false

    try {
        const updateData = new FormData();
        updateData.append("first_name", newUser.first_name)
        updateData.append("last_name", newUser.last_name)
        updateData.append("email", newUser.email)
        if (newUser?.password) {
            updateData.append("password", newUser?.password)
        }
        if (newUser.profil_image?.[0]) {
            updateData.append("profil_image", newUser.profil_image?.[0], newUser.profil_image[0]?.name)
        }

        const result = await fetch(url, {
            method: 'PATCH',
            body: updateData,
        });
        data = await result.json();
    } catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        user: data,
        error
    };
}

export async function updateUserRole(id, newUser) {
    const url = `/api/users/${id}`;
    let data = null;
    let error = ""
    let loaded = false

    try {
        const updateData = new FormData();
        updateData.append("default_role", newUser.default_role)

        const result = await fetch(url, {
            method: 'PATCH',
            body: updateData,
        });
        data = await result.json();
    } catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        user: data,
        error
    };
}

export async function createUser(newUser) {
    const url = `/api/users/`;
    let data = null;
    let error = ""
    let loaded = false

    try {
		const formData = new FormData();
		formData.append("first_name", newUser.first_name)
		formData.append("last_name", newUser.last_name)
		formData.append("is_staff", newUser.is_staff)
		if (newUser.profil_image?.length > 0) {
			formData.append("profil_image", newUser.profil_image?.[0], newUser.profil_image[0]?.name)
		}
		formData.append("email", newUser.email)
		formData.append("password", newUser.password)

		const result = await fetch(url, {
			method: 'POST',
			body: formData,
		});
		data = await result.json();
	} catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        user: data,
        error
    };
}

export function usePartialUpdateUser(id, token) {
    const {data, mutate, error, isValidating} = useSWR([`/api/users/${id}`, 'PATCH', token], fetchWithMethod);

    const loading = !data && !error;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        isValidating,
        user: data,
        mutate
    };
}

export async function deleteUser(id) {
    const url = `/api/users/${id}`;
    let data = null;
    let error = ""
    let loaded = false

    try {
        const result = await fetch(url, {
            method: 'DELETE',
            headers: new Headers({"Content-Type": "application/json"}),
            credentials: "include",
        });
        data = await result.json();
    } catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        user: data,
        error
    };
}

export async function userMe() {
    const url = '/api/users/me';
    let data = null;
    let error = ""
    let loaded = false

    try {
        const result = await fetch(url, {
            method: 'GET',
            headers: new Headers({"Content-Type": "application/json"}),
            credentials: "include",
        });
        data = await result.json();
    } catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        user: data,
        error
    };
}

export async function logout() {
    const url = `/api/users/logout/`;
    let data = null;
    let error = ""
    let loaded = false

    try {
        const result = await fetch(url, {
            method: 'POST',
            headers: new Headers({"Content-Type": "application/json"}),
            credentials: "include",
        });
        data = await result.json();
    } catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        user: data,
        error
    };
}

export async function retrieveStats() {
    const url = '/api/users/';
    let data = null;
    let error = ""
    let loaded = false

    try {
        const result = await fetch(url, {
            method: 'GET',
            headers: new Headers({"Content-Type": "application/json"}),
            credentials: "include",
        });
        data = await result.json();
    } catch (e) {
        error = e
    } finally {
        loaded = true
    }

    const loading = !data && !error && !loaded;
    const unauthorized = error && error.status === 401;

    return {
        loading,
        unauthorized,
        stats: data,
        error
    };
}
